<template>
    <div>
        <AppTable
            primaryKey="id"
            :columns="previewDataColumns"
            :rows="previewDataRows"
            :textEmpty="'Загрузка данных'"
            class="reconciliation-import-preview__table"
        />
    </div>
</template>

<script>
import AppTable from '../../Table2/Table.vue';
import ServiceReconciliationImport from '../../../services/ServiceReconciliationV2/ServiceReconciliationImport';
export default {
    name: 'ReconciliationImportPreview',
    components: {
        AppTable
    },
    data: () => ({
        previewData: {}
    }),
    computed: {
        fileId() {
            return String(this.$route.query?.fileId ?? '');
        },
        sheetId() {
            return String(this.$route.query?.sheetId ?? '');
        },
        previewDataColumns() {
            return this.previewData?.columns ?? []
        },
        previewDataRows() {
            return this.previewData?.rows ?? []
        }
    },
    mounted() {
        this.getPreview();
    },
    methods: {
        async getPreview() {
            this.previewData = await ServiceReconciliationImport.getPreview(this.fileId, this.sheetId);
        }
    }

}
</script>

<style lang="scss" scoped>
    .reconciliation-import-preview {
        &__table {
            width: 100%;
            height: auto;
            max-height: 100vh;
        }
    }
</style>